.container-fluid {
  min-height: calc(100vh - 7rem);
  height: max-content;
  padding: 0;
}

.container,
.container-lg {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

/* backgrounds */
.bg-dark {
  background-color: var(--bg-navy-2) !important;
}

.bg-linear {
  background: linear-gradient(180deg, #05346a00 0, var(--dark-navy) 100%) 0 0
    no-repeat padding-box;
}

.bg-radial {
  background: radial-gradient(
      closest-side at 50% 50%,
      var(--blue-2) -30%,
      var(--bg-2) 100%
    )
    0 0 no-repeat padding-box;
}

.bg-linear-radial {
  background: linear-gradient(180deg, #05346a00 0, var(--dark-navy) 100%) 0 0
    no-repeat padding-box;
}

.bg-info {
  background-color: #21a9ff !important;
}

.bg-warning {
  background-color: #f09825 !important;
}

.bg-gray {
  background-color: var(--purple);
}

.bg-navy {
  background-color: var(--input);
}

.bg-blue {
  background-color: var(--bg-blue);
}

.bg-black {
  background-color: rgba(var(--bg-1-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  background-color: var(--bg-light) !important;
}

.bg-secondary {
  background-color: var(--secondary) !important;
}

/* borders */

.border-secondary {
  border-color: var(--input) !important;
}

.border-primary {
  border-color: var(--blue) !important;
}

/* text */

.text-muted {
  color: var(--grey) !important;
}

.text-primary {
  color: var(--blue) !important;
}

.navy-text {
  color: var(--disabled);
}

.white-text {
  color: var(--white);
}

.text-danger {
  color: #ff4032 !important;
}

/* icons */
.icon-gray svg path {
  fill: #a0a1b5 !important;
}

.icon-blue svg path {
  fill: #0679fc;
}

.icon-white svg path {
  fill: #ffffff;
}

.icon-black svg path {
  fill: #000000;
}
.icon-transparent svg path {
  fill: #00000000;
}

.icon-danger svg path {
  fill: var(--danger);
}

.icon-navy {
  fill: #093d78;
}

.icon-font-color svg path{
  fill: var(--white);
}

/* navbar */
.navbar-panel,
.navbar-nav {
  background: var(--bg-1) !important;
}

.nav-link {
  color: var(--grey);
}

.nav-link:hover {
  color: var(--blue);
}

.active {
  color: var(--white);
}

/* inputs */
.form-control,
.form-select {
  background-color: var(--input);
  border-color: var(--input);
  color: var(--white);
  font-size: 0.75rem !important;
}

.modal-body .form-control, .modal-body .form-select{
  background-color: rgba(var(--bg-1-rgb), 0.25) !important;
}

.form-control:hover,
.form-select:hover {
  box-shadow: 0 0 10px #0f7ef880;
}

.form-control:focus,
.form-select:focus {
  background-color: var(--input);
  box-shadow: 0 0 10px #0f7ef880;
  border: 2px solid #2680eb;
  opacity: 1;
  color: var(--white);
}

.form-control:disabled,
.form-select:disabled {
  background-color: var(--disabled) !important;
  pointer-events: none;
}

.form-floating label {
  color: var(--grey);
  font-size: 0.75rem !important;
  padding: 1.2rem 0.75rem;
}

.form-floating .required::after {
  content:" *";
  color:red;
}

.is-invalid {
  background: var(--input) 0 0 no-repeat padding-box;
  border: 2px solid #e02b33;
  border-radius: 6px;
  opacity: 1;
}

.is-invalid .form-control {
  color: #e02b33 !important;
  border: none;
}

.is-invalid label {
  color: #e02b33;
}

.invalid-feedback {
  color: #e02b33;
}

.form-floating label,
.dropdown-menu,
.btn {
  font-family: "Inter", sans-serif;
}

/* buttons */
.btn-primary {
  background-color: var(--blue) !important;
  border-color: var(--blue) !important;
}
.btn-primary:hover {
  background-color: var(--blue-hover) !important;
  border-color: var(--blue-hover) !important;
}

.btn-outline-primary {
  border: 2px solid var(--blue) !important;
}

.light-theme .btn-outline-primary {
  border: 2px solid var(--blue) !important;
  color: var(--blue) !important;
}
.light-theme .btn-outline-primary:hover {
  color: #ffffff !important;
}

.btn-outline-primary:hover app-icon path {
  fill: #ffffff;
  transition: 0.15s;
}

.btn-secondary {
  background-color: var(--secondary) !important;
  border-color: var(--secondary) !important;
}

.btn-secondary:hover {
  background-color: var(--secondary-hover) !important;
  border-color: var(--secondary-hover) !important;
}

.btn-outline-secondary {
  color: var(--white) !important;
  border: 2px solid var(--purple);
}

.btn-outline-secondary:hover {
  background: var(--purple) !important;
  border: 2px solid var(--purple) !important;
}

.btn-danger {
  background-color: var(--danger) !important;
  border-color: var(--danger) !important;
}

.btn-danger:hover {
  background-color: var(--danger-hover) !important;
  border-color: var(--danger-hover) !important;
}

.btn-outline-danger {
  color: var(--white) !important;
  border: 2px solid var(--danger);
}

.btn-outline-danger:hover {
  background: var(--danger) !important;
  border: 2px solid var(--danger) !important;
}

.btn-sm {
  padding: 0.5rem 0.75rem;
}

.btn-lg {
  padding: 0.9rem;
}

.btn {
  color: #ffffff;
  font-size: calc(0.75*var(--font-size));
}
.btn:focus,
.btn:focus-visible {
  box-shadow: none !important;
}

.btn-link {
  color: var(--blue);
}

.btn-close:hover {
  scale: 1.3;
  transition: ease-in 0.15s;
}

/* buttons states */
.ready {
  background: #0679fc;
}
.ready:hover {
  background: #0765d1;
  color: var(--white);
}

.perfect {
  background: #27a51c;
}
.perfect:hover {
  background: #218518;
  color: var(--white);
}

.good {
  background: #70c508;
}
.good:hover {
  background: #62ac09;
  color: var(--white);
}

.enough {
  background: #f09800;
}
.enough:hover {
  background: #ca8000;
  color: var(--white);
}

.bad {
  background: #ff4814;
}
.bad:hover {
  background: #cf3c14;
  color: var(--white);
}

.locked {
  background: var(--locked);
  pointer-events: none;
}
.locked:hover {
  background: #343e5b;
  color: var(--white);
}
/* radio */
.form-check-input[type="radio"] {
  background-color: transparent !important;
  border: 2px solid var(--grey);
  border-radius: 8px;
}
.form-check-input[type="radio"]:checked {
  background-color: var(--blue) !important;
  border: none;
}

.form-check-input[type="radio"][disabled] {
  color: var(--disabled) !important;
}

.form-check-input[type="radio"][disabled]:checked {
  background-color: transparent !important;
  border: 2px solid var(--grey);
}

/* select  */
.form-select {
  background-color: var(--input);
  border-color: transparent;
  font-size: 0.75rem !important;
  height: calc(3.5rem + 2px);
  text-align: left;
  font-family: "Inter", sans-serif;
  color: var(--white);
  background-image: url("assets/angle-down.svg");
  padding-left: 0.75rem;
}
.form-select ~ label {
  opacity: 1 !important;
  transform: unset !important;
}
.select-not-empty ~ label {
  opacity: 0.65 !important;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem) !important;
}

.form-select:disabled {
  background-color: var(--disabled) !important;
}
/* toast notifications  and confirm dialog*/

.modal-dialog {
  margin: auto !important;
}
.modal-content {
  top: 7rem;
}

.toast-list {
  position: fixed;
  width: calc(100% - 1rem);
  bottom: 7.5rem;
  margin-inline: 0.5rem;
}

.toast,
.modal-content {
  background-color: var(--blue);
  border: 3px solid var(--blue);
  border-radius: 0.25rem;
  font-size: 0.8rem;
  margin-top: 2rem;
}

.toast-header,
.modal-header {
  font-family: "Inter", sans-serif;
  padding: 1rem 1.5rem;
  background: none;
  color: #FFFFFF;
}

.toast:hover {
  background: #0765d1;
  border: 3px solid #0765d1;
}

.toast-body,
.modal-body,
.modal-footer {
  background-color: var(--input);
}

.toast-body {
  padding: 1.25rem 1.5rem 2rem 1.5rem;
}

.modal-body,
.modal-footer {
  padding-inline: 1.5rem;
}

@media only screen and (min-width: 420px) {
  .toast-list {
    width: fit-content;
    right: 1.5rem;
  }
}

@media only screen and (min-width: 768px) {
  .container,
  .container-lg {
    padding: 0 3rem;
    margin-bottom: 0;
  }

  .bg-linear-radial {
    background: radial-gradient(
        closest-side at 50% 50%,
        var(--blue-2) -30%,
        var(--bg-2) 100%
      )
      0 0 no-repeat padding-box;
  }
  .breadcrumb {
    padding-top: 0.5rem;
    --bs-breadcrumb-divider: ">";
    --bs-breadcrumb-divider-color: var(--purple);
    font-size: 0.8rem;
  }

  .breadcrumb a {
    color: var(--breadcrumb) !important;
    text-decoration: none;
    cursor: pointer;
  }
  .breadcrumb a:hover {
    color: var(--blue) !important;
  }

  .breadcrumb .active {
    color: var(--white);
  }

  .toast-list {
    right: 2rem;
    bottom: 2rem;
  }
}
@media screen and (min-width: 992px) {
  .container,
  .container-lg {
    padding: 0 6rem;
  }
}
